<template>
    <Teleport to="body" class="modal-custom-window">
        <div v-show="showModal" v-bind="attrs" class="modal-window">
            <div class="modal-body">
                <div class="container-xl text-end">
                    <span v-dompurify-html="CloseButton" class="close-button" @click="showModal = false" />
                </div>
                <div class="container-xl modal-body-container">
                    <div class="content-body col-12">
                        <slot></slot>
                    </div>
                </div>
            </div>
        </div>
    </Teleport>
</template>
<script setup lang="ts">
import CloseButton from '~/assets/images/icons/icon_close.svg?raw';
import { useVModel } from '@vueuse/core';
const attrs = useAttrs();
const props = defineProps<{ modelValue: Boolean }>();
const emit = defineEmits<{ (e: 'update:modelValue', value: Boolean): void }>();
const showModal = useVModel(props, 'modelValue', emit);
</script>

<style scoped>
.modal-window {
    position: fixed;
    display: flex;
    align-items: center;
    z-index: 999;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background: rgba(0, 0, 0, 0.5);
    overflow: auto;
}
.success,
.error {
    .modal-body {
        max-width: 30rem;
        margin: 0 auto;
    }
    .close-button ::v-deep(svg) {
        margin-right: 0.7rem;
        margin-bottom: -4.5rem;
        cursor: pointer;
        stroke: black;
    }
}

.modal-body {
    z-index: 3;
}
.content-body {
    background: white;
    padding: 3.75rem;
    box-shadow: 0px 4px 16px 0px rgba(209, 209, 209, 0.25);
    border-radius: 0.1875rem;
}
.close-button ::v-deep(svg) {
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    stroke: white;
}
.close-button ::v-deep(svg:hover) {
    stroke: burlywood;
}
@media (max-width: 1000px) {
    .modal-window {
        overflow: auto;
    }
    .close-button ::v-deep(svg) {
        margin-right: 0.7rem;
        margin-bottom: -4.5rem;
        cursor: pointer;
        stroke: black;
    }
    .content-body {
        padding: 2.5rem 3.75rem;
    }
}
@media (max-width: 480px) {
    .content-body {
        padding: 1rem 2rem;
    }
}
</style>
